import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { getImagePreview } from 'utils';

import { AlternativeStoryHeader, Layout, Paragraphs, SEO, StoryHeader } from 'components';

import SocialImage from '../../static/anyforsoft_social.jpg';

const StoryTemplate = ({
  data: {
    nodeStory: {
      field_alternative_design,
      title,
      field_headline,
      path: { alias },
      field_metatags,
      field_country,
      field_industry,
      field_industry_link,
      field_discover_more_link,
      field_description,
      field_what_to_display,
      field_video_autoplay_status,
      field_video_url,
      field_image: { alt },
      field_automatic_breadcrumbs,
      relationships: {
        field_image_header,
        field_image,
        field_json_lottie,
        field_video_file,
        field_video_file_preview,
        field_content,
        field_breadcrumbs,
      },
    },
  },
}) => {
  const storyTitle = title.split(' ').join('_');

  const imageHeader = field_image?.localFile?.childImageSharp || SocialImage;
  const imageOpenGraphPreview = field_video_file_preview?.localFile
    ? field_video_file_preview.localFile.childImageSharp.fluid.src
    : field_image_header?.localFile.childImageSharp.fluid.src;

  const imageUrl = getImagePreview(imageOpenGraphPreview, imageHeader);

  return (
    <Layout
      type="story"
      nodeTitle={field_metatags?.title || title}
      parentBreadcrumb="/stories-list/"
      customBreadcrumbs={field_breadcrumbs}
      isDefaultBreadcrumbs={field_automatic_breadcrumbs}
      parentTitle="Portfolio"
      isAlternativeHeader={field_alternative_design}
    >
      <SEO
        title={field_metatags?.title || title}
        description={field_metatags?.description || ''}
        keywords={field_metatags?.keywords || ''}
        robots={field_metatags?.robots}
        currentUrl={alias}
        imageUrl={imageUrl}
      />
      <Helmet
        bodyAttributes={{
          storyName: storyTitle,
        }}
      />
      <div>
        {field_alternative_design ? (
          <AlternativeStoryHeader
            data={{
              title,
              field_headline,
              field_country,
              field_industry,
              field_industry_link,
              field_discover_more_link,
              field_description,
            }}
          />
        ) : (
          <StoryHeader
            data={{
              title,
              field_description,
              field_what_to_display,
              field_json_lottie,
              field_image,
              alt,
              field_video_file,
              field_video_file_preview,
              field_video_autoplay_status,
              field_video_url,
            }}
          />
        )}
        {field_content.map((item) => (
          <Paragraphs key={uid(item)} data={item} />
        ))}
      </div>
    </Layout>
  );
};

StoryTemplate.propTypes = {
  data: PropTypes.shape({
    nodeStory: PropTypes.shape({
      field_alternative_design: PropTypes.bool,
      title: PropTypes.string,
      field_headline: PropTypes.string,
      path: PropTypes.shape({
        alias: PropTypes.string,
      }),
      field_metatags: PropTypes.object,
      field_industry_link: PropTypes.object,
      field_country: PropTypes.string,
      field_industry: PropTypes.string,
      field_discover_more_link: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
      }),
      field_description: PropTypes.object,
      field_what_to_display: PropTypes.string,
      field_video_autoplay_status: PropTypes.bool,
      field_video_url: PropTypes.object,
      field_image: PropTypes.shape({
        alt: PropTypes.string,
      }),
      field_automatic_breadcrumbs: PropTypes.bool,
      relationships: PropTypes.shape({
        field_content: PropTypes.array,
        field_image: PropTypes.object,
        field_image_header: PropTypes.object,
        field_json_lottie: PropTypes.object,
        field_video_file: PropTypes.object,
        field_video_file_preview: PropTypes.object,
        field_breadcrumbs: PropTypes.array,
      }),
    }),
  }),
};

export default StoryTemplate;

export const query = graphql`
  query($drupal_id: String!) {
    nodeStory(drupal_id: { eq: $drupal_id }) {
      ...NodeStoryPage
    }
  }
`;
